import React from 'react';

import { LinkI } from '../header/links';
import Caret from '../../assets/icons/caret.svg';
import CustomLink from '../customLink/CustomLink';

interface Props {
	link: LinkI;
	closeMenu: () => void;
}

export default function LinksDrawer({ link, closeMenu }: Props) {
	return (
		<div className="links-drawer py-30">
			<div className="links-parent row">
				<CustomLink href={link.href} title={link.title} type="text" closeMenu={closeMenu} />
				<div className="row align-center justify-center icon">
					<Caret />
				</div>
			</div>

			<div className="dropdown-wrapper">
				<div className="links-dropdown">
					{link.links?.map((el) => (
						<CustomLink key={el.title} href={el.href} title={el.title} type="text" closeMenu={closeMenu} />
					))}
				</div>
			</div>
		</div>
	);
}
