import React from 'react';
import { Link } from 'gatsby';

interface Props {
	href: string;
	title: string;
	type?: string;
	classes?: string;
	closeMenu?: () => void;
}

export default function CustomLink({ href, title, type = 'default', classes = '', closeMenu }: Props) {
	let spanClassName = '';

	if (type === 'en') {
		spanClassName = 'england';
	} else if (type === 'de') {
		spanClassName = 'germany';
	}
	return (
		<Link className={`custom-link ${type} ${classes}`} to={href} onClick={closeMenu}>
			<span className={spanClassName}>{title}</span>
		</Link>
	);
}
