import React from 'react';

import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import { links } from '../components/header/links';
import { footerLinks } from '../components/footer/links';
import { PageProps } from 'gatsby';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CookieBanner from '../components/cookieBanner/CookieBanner';

interface PageContext {
	langKey: 'en' | 'de';
	slug: string;
}

const Layout = ({ pageContext: { langKey }, children }: PageProps<object, PageContext>) => (
	<>
		<Header langKey={langKey} links={links[langKey]} />
		<main>{children}</main>
		<Footer langKey={langKey} links={footerLinks[langKey]} />
		<ToastContainer theme="light" />
		<CookieBanner langKey={langKey} />
	</>
);

export default Layout;
