import React, { useState, useEffect } from 'react';

declare global {
	interface Window {
		dataLayer: any[];
		gtag: (...args: any[]) => void;
	}
}

interface Props {
	langKey: 'en' | 'de';
}

const CookieBanner = ({ langKey }: Props) => {
	const [showBanner, setShowBanner] = useState(false);

	useEffect(() => {
		const cookiesAccepted = localStorage.getItem('cookiesAccepted');
		if (!cookiesAccepted) {
			setShowBanner(true);
		} else {
			loadGoogleAnalytics();
		}
	}, []);

	const handleAcceptCookies = () => {
		localStorage.setItem('cookiesAccepted', 'true');
		setShowBanner(false);
		loadGoogleAnalytics();
	};

	const handleDeclineCookies = () => {
		localStorage.setItem('cookiesAccepted', 'false');
		setShowBanner(false);
	};

	const loadGoogleAnalytics = () => {
		const script = document.createElement('script');
		script.src = 'https://www.googletagmanager.com/gtag/js?id=G-4KQF03GS3T';
		script.async = true;
		document.body.appendChild(script);

		script.onload = () => {
			window.dataLayer = window.dataLayer || [];
			window.gtag = function gtag() {
				window.dataLayer.push(arguments as any);
			};
			window.gtag('js', new Date());
			window.gtag('config', 'G-4KQF03GS3T', { anonymize_ip: true });
		};
	};

	return (
		showBanner && (
			<div className="cookie-banner">
				<p className="t5 text-white">
					{langKey === 'en'
						? 'We use cookies to enhance your experience.'
						: 'Wir verwenden Cookies, um Ihr Erlebnis zu verbessern.'}
				</p>
				<div>
					<button className="pointer cookie-banner-button t5-m" onClick={handleAcceptCookies}>
						{langKey === 'en' ? 'Accept' : 'Akzeptieren'}
					</button>
					<button className="pointer cookie-banner-button  t5" onClick={handleDeclineCookies}>
						{langKey === 'en' ? 'Decline' : 'Ablehnen'}
					</button>
				</div>
			</div>
		)
	);
};

export default CookieBanner;
