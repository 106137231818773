import React, { useEffect, useState } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { LinkI } from './links';
import CustomLink from '../customLink/CustomLink';
import LinksDrawer from '../linksDrawer/LinksDrawer';
import LangSwitch from '../langSwitch/LangSwitch';

interface Props {
	langKey: 'en' | 'de';
	links: LinkI[];
}

export default function Header({ langKey, links }: Props) {
	const [isOpen, setIsOpen] = useState(false);

	const closeMenu = () => setIsOpen(false);

	useEffect(() => {
		const handleScroll = () => {
			if (isOpen) {
				closeMenu();
			}
		};
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [isOpen]);

	const data = useStaticQuery(graphql`
		{
			markdownRemark(fileAbsolutePath: { regex: "/comps/" }) {
				frontmatter {
					deImageAlt
					enImageAlt
					deLinkHref
					enLinkHref
					featuredImage {
						childImageSharp {
							gatsbyImageData(aspectRatio: 3.7, width: 130, quality: 100)
						}
					}
				}
			}
		}
	`);

	return (
		<header className="header">
			{data.markdownRemark && (
				<Link to={data.markdownRemark.frontmatter[`${langKey}LinkHref`]} className="py-27">
					<GatsbyImage
						image={getImage(data.markdownRemark.frontmatter.featuredImage)!}
						alt={data.markdownRemark.frontmatter[`${langKey}ImageAlt`]}
						loading="eager"
					/>
				</Link>
			)}

			<div className={`nav-toggle ${isOpen && 'open'}`} onClick={() => setIsOpen((prev) => !prev)}>
				<div className="bar"></div>
			</div>
			<div className={`row header-links align-center justify-center ${isOpen && 'open'}`}>
				{links.map((el) =>
					el.links ? (
						<LinksDrawer key={el.title} link={el} closeMenu={closeMenu} />
					) : (
						<CustomLink
							key={el.title}
							href={el.href}
							title={el.title}
							type="text"
							classes={isOpen ? 'py-10' : 'py-30'}
							closeMenu={closeMenu}
						/>
					)
				)}

				{/* <CustomLink {...contactData[langKey]} classes="text py-30" /> */}

				<LangSwitch langKey={langKey} />
			</div>
		</header>
	);
}

/* const contactData = {
	de: {
		title: 'Kontakt',
		href: '/de/kontakt',
	},
	en: {
		title: 'Contact',
		href: '/en/contact',
	},
}; */
