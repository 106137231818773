/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import React from 'react';
import './src/styles/main.scss';
import Layout from './src/layouts/layout';

declare global {
	interface Window {
		gtag: (...args: any[]) => void;
	}
}

export const wrapPageElement = ({ element, props }) => <Layout {...props}>{element}</Layout>;

export const onRouteUpdate = ({ location }) => {
	const cookiesAccepted = localStorage.getItem('cookiesAccepted');

	if (cookiesAccepted === 'true') {
		if (window.gtag) {
			window.gtag('js', new Date());
			window.gtag('config', 'G-4KQF03GS3T', {
				anonymize_ip: true,
				page_path: location.pathname,
				page_title: document.title,
			});

			window.gtag('event', 'page_view', {
				page_path: location.pathname,
				page_title: document.title,
			});
		}
	}
};
