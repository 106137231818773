import React from 'react';
import { FooterLinks } from './links';
import CustomLink from '../customLink/CustomLink';
import Facebook from '../../assets/icons/facebook.svg';
import Instagram from '../../assets/icons/instagram.svg';
import Linkedin from '../../assets/icons/linkedin.svg';
import { Link } from 'gatsby';

interface Props {
	links: FooterLinks[];
	langKey: 'en' | 'de';
}

const Footer = ({ links, langKey }: Props) => {
	return (
		<footer className="footer column">
			<div className="row justify-between flex-1">
				<div className="info">{data.info[langKey]}</div>

				<div className="links row">
					{links.map((el, i0) => (
						<div key={i0} className="column footer-company-links2">
							{el.titles.map((el, i1) =>
								el.href === '/' ? (
									<h6 key={i0 + i1} className="mb-20 company-footer">
										{el.title}
									</h6>
								) : (
									<Link key={i0 + i1} className="h6 text-white mb-10" to={el.href}>
										<h6 className="mb-20">{el.title}</h6>
									</Link>
								)
							)}
							{el.links?.map((link, i2) => (
								<CustomLink
									key={i0 + i2}
									href={link.href}
									title={link.title}
									classes="t4-r text-white mb-10 footer-company-links"
									type="plain"
								/>
							))}
						</div>
					))}
					<div className="column">
						{data.contactLink[langKey]}

						<div className="contact-footer">
							<p className="t4-r text-white">Jebens GmbH</p>
							<p className="t4-r text-white">Daimlerstraße 35-37</p>
							<p className="t4-r text-white mb-10">70825 Korntal-Münchingen</p>
							<a href="tel:+4971180020" className="t4-r text-white">
								Tel.: +49 (0) 711 80 02-0
							</a>
							<br></br>
							<a href="mailto:info@jebens.dillinger.biz" className="t4-r text-white">
								Mail: info@jebens.dillinger.biz
							</a>
						</div>
					</div>
				</div>
			</div>

			<hr className="hr" />

			<div className="row flex-1 justify-between footer-bottom">
				<div className="row text-white footer-privacy-imprint">
					<p className="t4-r footer-company-year">{new Date().getFullYear()} © JEBENS - MASSARBEIT IN STAHL </p>
					<p className="t4-r mx-5 union">|</p>
					<div className="footer-privacy-imprint">
						{data.privacy[langKey]}

						<span className="t4-r mx-5" aria-hidden="true">
							|
						</span>

						{data.imprint[langKey]}
						<span className="t4-r mx-5" aria-hidden="true">
							|
						</span>

						{data.whisteblowerSystem[langKey]}
					</div>
				</div>

				<div className="row social footer-social">
					<a href="https://www.instagram.com/jebens_gmbh/" aria-label="Jebens Instagram">
						<Instagram />
					</a>
					<a href="https://www.linkedin.com/company/jebens-gmbh/" aria-label="Jebens LinkedIn">
						<Linkedin />
					</a>
					<a href="https://www.facebook.com/jebenssteel" aria-label="Jebens Facebook">
						<Facebook />
					</a>
				</div>
			</div>
		</footer>
	);
};

export default Footer;

const data = {
	info: {
		de: (
			<>
				<Link to="/" aria-label="link to homepage">
					<h2 className="footer-company-title pr-10">
						<span>JEBENS</span>
					</h2>
				</Link>
				<p className="text-white t4-r footer-text pr-10">
					Mit langjähriger Expertise in der Stahlbranche bieten wir unseren Kunden qualitativ hochwertige Produkte und
					maßgeschneiderte Lösungen, die ihren individuellen Anforderungen entsprechen.
				</p>
			</>
		),
		en: (
			<>
				<Link to="/en" aria-label="link to homepage">
					<h2 className="footer-company-title pr-10">
						<span>JEBENS</span>
					</h2>
				</Link>
				<p className="text-white t4-r footer-text pr-10">
					We offer our customers high-quality products and customized solutions that meet their individual requirements
					thanks to many years of expertise in the steel industry.
				</p>
			</>
		),
	},
	contactLink: {
		de: (
			<Link className="h6 mb-20 text-white footer-contact-link" to="/de/kontakt/">
				Kontakt
			</Link>
		),
		en: (
			<Link className="h6 mb-20 text-white footer-contact-link" to="/en/contact/">
				Contact
			</Link>
		),
	},
	privacy: {
		de: (
			<Link className="t4-r text-white" to="/de/datenschutzerklaerung/">
				Datenschutzerklärung
			</Link>
		),
		en: (
			<Link className="t4-r text-white" to="/en/privacy-policy/">
				Privacy policy
			</Link>
		),
	},
	imprint: {
		de: (
			<Link className="t4-r text-white" to="/de/impressum/">
				Impressum
			</Link>
		),
		en: (
			<Link className="t4-r text-white" to="/en/imprint/">
				Imprint
			</Link>
		),
	},
	whisteblowerSystem: {
		de: (
			<Link className="t4-r text-white" to="/de/impressum/">
				Hinweisgebersystem
			</Link>
		),
		en: (
			<Link className="t4-r text-white" to="/en/imprint/">
				Whistleblower system
			</Link>
		),
	},
};
