module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jebens GmbH - Massarbeit in Stahl","short_name":"Jebens GmbH","description":"Massarbeit in Stahl. Wahre Größe zeigt sich im Detail. Das ist bei dicken, schweren Brennteilen, Brennzuschnitten und komplexen Schweißbaugruppen aus Stahl nicht...","lang":"de","start_url":"/","background_color":"#ffffff","theme_color":"#004b87","display":"minimal-ui","icon":"src/images/jebensLogo.jpeg","localize":[{"start_url":"/en/","lang":"en","name":"Jebens GmbH - Custom made steel","short_name":"Jebens GmbH","description":"Custom made steel. True greatness is revealed in the details."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"35e8184614a96a0f56b3ae7f9e1ca01a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-decap-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://jebens.de","stripQueryString":true},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
