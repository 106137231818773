import React from 'react';
import { useLocation } from '@reach/router';

import Caret from '../../assets/icons/caret.svg';
import CustomLink from '../customLink/CustomLink';

export const DE_LINKS = {
	'/en/': '/',
	'/en/carrers/': '/de/karriere/',
	'/en/about-us/': '/de/uber-uns/',
	'/en/quality-certifications/': '/de/qualitat-zertifizierungen/',
	'/en/contact-persons/': '/de/unsere-ansprechpartner/',
	'/en/webinar/': '/de/webinar/',
	'/en/newsletter/': '/de/newsletter/',
	'/en/contact/': '/de/kontakt/',
	'/en/services/': '/de/dienstleistungen/',
	'/en/services/flame-cut-parts/': '/de/dienstleistungen/brennteile/',
	'/en/services/welded-components/': '/de/dienstleistungen/schweissbaugruppen/',
	'/en/services/all-round-consulting/': '/de/dienstleistungen/komplette-losungen/',
	'/en/services/steel-trading/': '/de/dienstleistungen/handel/',
	'/en/privacy-policy/': '/de/datenschutzerklaerung/',
	'/en/imprint/': '/de/impressum/',
	'/en/technologies/': '/de/technologien/',
	'/en/apply-for-job/': '/de/bewerbung/',
};
export const EN_LINKS = {
	'/': '/en/',
	'/de/karriere/': '/en/carrers/',
	'/de/uber-uns/': '/en/about-us/',
	'/de/qualitat-zertifizierungen/': '/en/quality-certifications/',
	'/de/unsere-ansprechpartner/': '/en/contact-persons/',
	'/de/webinar/': '/en/webinar/',
	'/de/newsletter/': '/en/newsletter/',
	'/de/kontakt/': '/en/contact/',
	'/de/dienstleistungen/': '/en/services/',
	'/de/dienstleistungen/brennteile/': '/en/services/flame-cut-parts/',
	'/de/dienstleistungen/schweissbaugruppen/': '/en/services/welded-components/',
	'/de/dienstleistungen/komplette-losungen/': '/en/services/all-round-consulting/',
	'/de/dienstleistungen/handel/': '/en/services/steel-trading/',
	'/de/datenschutzerklaerung/': '/en/privacy-policy/',
	'/de/impressum/': '/en/imprint/',
	'/de/technologien/': '/en/technologies/',
	'/de/bewerbung/': '/en/apply-for-job/',
};

interface Props {
	langKey: 'en' | 'de';
}

const LangSwitch = ({ langKey }: Props) => {
	const { pathname } = useLocation();

	return (
		<div className="lang-switch py-30">
			<div className="switch-title row">
				{data.language[langKey]}
				<div className="row align-center justify-center icon">
					<Caret />
				</div>
			</div>

			<div className="switch-wrapper">{data.switch[langKey]?.(pathname)}</div>
		</div>
	);
};

export default LangSwitch;

const data = {
	language: {
		de: (
			<p className="country-flag-de active">
				<span className="germany">DE</span>
			</p>
		),
		en: (
			<p className="country-flag-en active">
				<span className="england">EN</span>
			</p>
		),
	},
	switch: {
		de: (pathname: string) => (
			<div className="switch-dropdown country-flag-en">
				<CustomLink href={EN_LINKS[pathname as keyof typeof EN_LINKS] || ''} title="EN" type="text" />
			</div>
		),
		en: (pathname: string) => (
			<div className="switch-dropdown country-flag-de">
				<CustomLink href={DE_LINKS[pathname as keyof typeof DE_LINKS] || ''} title="DE" type="text" />
			</div>
		),
	},
};
